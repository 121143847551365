import { createSelector } from 'reselect';

import {
  ARROW_LEFT_RIGHT,
  BED,
  CALENDAR,
  PET_FRIENDLY,
  RUNNING_VEHICLE,
  SETUP_ONLY,
  USERS,
} from '@/components/switchback/Icon/assets';
import { IconType } from '@/components/switchback/Icon/IconComponent';
import { ECancellationPolicies } from '@/constants/houseRules';
import { TRootState } from '@/redux/rootReducer';
import { ECampsiteCategoryType, ERentalCategory } from '@/services/types/search/rentals/id';
import { getIntl } from '@/utility/i18n';
import { formatMeasurement } from '@/utility/measurements';
import {
  getCampsiteTypeDisplay,
  getStayTypeDisplay,
  getVehicleTypeDisplay,
} from '@/utility/rentals';
import { mapPluralUnitToSingular } from '@/utility/units';

type TListingData = TRootState['listing']['data'];

interface IInfo {
  label: string;
  icon: IconType;
}

interface IListingHeader {
  title: string;
  infos: IInfo[];
  locationPrefix?: string;
  location: string;
  accommodationType: string;
  ratingNumber?: number;
  reviewsCount?: number;
  dealer?: boolean;
  isSuperhost?: boolean;
}

export const getIsRV = (state: TRootState) => state.listing.data?.rental_category === 'rv';
export const getIsStay = (state: TRootState) => state.listing.data?.rental_category === 'stay';
export const getDisallowMovement = (state: TRootState) =>
  state.listing.data?.disallow_movement || false;

export const getSleepCount = (state: TRootState) =>
  state.listing.data?.campsite_category?.max_occupancy ||
  state.listing.data?.stay?.sleeps ||
  state.listing.data?.sleeps;

export const getIsCampground = (state: TRootState) =>
  state.listing.data?.rental_category === 'campground';

export const getListingHeader = createSelector<TRootState, TListingData, IListingHeader | null>(
  state => state.listing.data,
  data => {
    if (!data) {
      return null;
    }

    const intl = getIntl();

    const locationPrefix =
      data.rental_category === ERentalCategory.CAMPGROUND
        ? intl.formatMessage({ defaultMessage: 'Campground in', id: '7C1LoC' })
        : undefined;

    const location = `${data.location.city}, ${data.location.state || data.location.county}`;

    const accommodationType: string =
      data.rental_category === ERentalCategory.CAMPGROUND
        ? getCampsiteTypeDisplay(data.campsite_category.display_category_type)
        : data.rental_category === 'stay'
          ? getStayTypeDisplay(data.stay.display_stay_type)
          : getVehicleTypeDisplay(data.display_vehicle_type);

    const sleepsNum: number =
      data.campsite_category?.max_occupancy || data.stay?.sleeps || data.sleeps;
    const bedsNum: number = data.campsite_category?.beds || data.stay?.beds || 0;

    const guests: IInfo | undefined = data.seatbelts
      ? {
          icon: USERS,
          label: intl.formatMessage(
            { defaultMessage: '{guests, plural, one {# guest} other {# guests}}', id: '1ahDyv' },
            { guests: data.seatbelts },
          ),
        }
      : undefined;
    const cancellation: IInfo | undefined =
      data.cancel_policy === ECancellationPolicies.FLEXIBLE
        ? {
            icon: CALENDAR,
            label: intl.formatMessage({ defaultMessage: 'Flexible cancellation', id: 'rObfLp' }),
          }
        : undefined;

    const sleeps: IInfo | undefined = sleepsNum
      ? {
          icon: BED,
          label: intl.formatMessage(
            { defaultMessage: 'Sleeps {beds}', id: 'oxlpEG' },
            { beds: sleepsNum },
          ),
        }
      : undefined;
    const beds: IInfo | undefined = bedsNum
      ? {
          icon: BED,
          label: intl.formatMessage(
            { defaultMessage: 'Beds {beds}', id: 'LUCOMX' },
            { beds: bedsNum },
          ),
        }
      : undefined;
    const setupOnly: IInfo | undefined = data.disallow_movement
      ? {
          icon: SETUP_ONLY,
          label: intl.formatMessage({ defaultMessage: 'Setup only', id: 'XGNs8R' }),
        }
      : undefined;

    const lengthUnit = mapPluralUnitToSingular(data.locale?.length_unit);
    const length: IInfo | undefined =
      lengthUnit &&
      data.vehicle_length &&
      formatMeasurement(data.vehicle_length, { unit: lengthUnit })
        ? {
            icon: ARROW_LEFT_RIGHT,
            label: `${formatMeasurement(data.vehicle_length, { unit: lengthUnit })}. long`,
          }
        : undefined;

    const maxVehicleLength: IInfo | undefined =
      data.rental_category === ERentalCategory.CAMPGROUND &&
      data.campsite_category?.category_type === ECampsiteCategoryType.RV_SITE &&
      data.campsite_category?.max_vehicle_length
        ? {
            icon: ARROW_LEFT_RIGHT,
            label: intl.formatMessage(
              { defaultMessage: 'RVs up to {length}{lengthUnit}', id: 'saHenM' },
              {
                length: data.campsite_category.max_vehicle_length,
                lengthUnit: 'ft', // TODO: backend to allow for Locale and meters
              },
            ),
          }
        : undefined;

    const isPetFriendly = (() => {
      if (data.rental_category === ERentalCategory.CAMPGROUND) {
        return data.campground?.features?.allows_pets;
      }

      if (data.rental_category === 'stay') {
        return data.stay?.features.pet_friendly;
      }

      return data.features?.pet_friendly;
    })();

    const petFriendly: IInfo | undefined = isPetFriendly
      ? {
          icon: PET_FRIENDLY,
          label: intl.formatMessage({
            defaultMessage: 'Pet friendly',
            id: 'c8hn0D',
          }),
        }
      : undefined;

    const hasDelivery = data.delivery && data.delivery_radius > 0;
    const offersDelivery: IInfo | undefined = hasDelivery
      ? {
          icon: RUNNING_VEHICLE,
          label: intl.formatMessage({
            defaultMessage: 'Offers delivery',
            id: 'sawrL+',
          }),
        }
      : undefined;

    const infos =
      data.rental_category === ERentalCategory.STAY ||
      data.rental_category === ERentalCategory.CAMPGROUND
        ? ([sleeps, beds, maxVehicleLength, cancellation, petFriendly].filter(Boolean) as IInfo[])
        : ([setupOnly, guests, sleeps, length, petFriendly, offersDelivery].filter(
            Boolean,
          ) as IInfo[]);

    const ratingNumber = data.score;
    const reviewsCount = ratingNumber ? data.reviews_num : undefined;
    const isSuperhost = data.tags?.some(tag => tag.slug === 'superhost');

    return {
      title: data.name,
      infos,
      accommodationType,
      locationPrefix,
      location,
      ratingNumber,
      reviewsCount,
      campsiteCategoryType: data.campsite_category?.category_type,
      dealer: data.dealer,
      isSuperhost,
    };
  },
);
